<script setup lang="ts">
    import { MessagingWindow } from '@deegital/vue-trustup-io-messaging';
    import BaseModel from '~~/app/base/models/BaseModel';

    const { authed, tenant } = useAuthStore();
    const { trustupMessagingAppKey } = useRuntimeConfig().public;

    defineProps({
  model: null,
  modelPrefix: null,
  containerClassName: null
});
</script>

<template>
    <div
        class="h-full overflow-y-auto messaging-window-container nice-scrollbar"
        :class="containerClassName ?? ''"
    >
        <MessagingWindow
            :app-key="trustupMessagingAppKey"
            :model-type="modelPrefix || model.prefix"
            :model-id="model.getId()"
            :user-id="authed?.getId()"
            :account-id="tenant?.getId()"
            is-full
        />
    </div>
</template>

<style>
    /* .messaging-window-container .flex.flex-col.gap-3 > *:first-child {
        min-height: 600px;
    } */
</style>
